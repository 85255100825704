<template>
<div class="menuwrapper" @mouseleave="clearHover">

    <div class="menu-container flex-container" v-if="hovered">
        <div  v-for="(child) in getChildren(hovered)"
            :key="child.id">
                <div class="flex-items">
                    <span v-if="child.title" class="menu-subheading">{{ child.title }}</span>
                    <ul>
                        <li v-for="(subChild) in getChildren(child.id)" :key="subChild.id" >
                            <a :href="subChild.url">{{  subChild.title  }}</a>
                        </li>
                    </ul>
                </div>
        </div>
    </div>

    <nav class="nav-bar" :class="{hovering: isHovered}">
        <li v-for="(item) in parentItems"
        :key="item.id" class="top-level-item">
            <span @mouseenter="hovered=item.id; hoveredTitle = item.title; isHovered = true" v-if="isTitleOnly('title-menu-item', item.classes)" >{{ item.title }}</span>
            <a v-else @mouseenter="clearHover" :href="item.url" :class="{hovering: isHovered}" class="top-level-item">{{ item.title }}</a>
        </li>
    </nav>

</div>

</template>

<script>

import axios from 'axios'

export default {
  name: 'MainMenu',
  data(){
    return{
        items: [],
        isHovered: false,
        hovered: '',
        hoveredTitle: ''
    }
  },
  computed:{
    parentItems(){
        return this.items.filter(d => d.parentId === '0')
    },
  },
  methods:{
    requestItems() {
      axios.request({
        url: "https://www.bopydt.co.nz/wp-json/custom/menu/20"
      }).then(resp => {
        const toAdd = resp.data
          .filter(d => {
            return d.ID
              && d.title
              && d.menu_order
          })
          .map(d => {
            return {
              id: d.ID,
              url: d.url,
              title: d.title,
              pageurl: d.url,
              menuorder: d.menu_order,
              parentId: d.menu_item_parent,
              classes: d.classes
            }
          })

        toAdd.forEach(item => {
          this.items.push(item)
        });

      })
      .catch(err => {
        if (err?.response?.status == 400 && this.items.length) {
          // happens if the last page was full but there is no
        }
        else {
          console.log(err)
        }
      })
    },
    getChildren(parentId){
        return this.items.filter( d => d.parentId == parentId)
    },
    clearHover(){
        this.hovered = "",
        this.hoveredTitle = "",
        this.isHovered = false
    },

    isTitleOnly(titleClass, array) {
        let length = array.length;
        for(let i = 0; i < length; i++) {
            if(array[i] == titleClass) return true;
        }
        return false;
    }


  },
  mounted() {
    this.requestItems();
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
$small: 1340px;


.wb-mega-menu{
    ul {
    list-style-type: none;
    padding: 0;
    }
    li {
    display: inline-block;
    margin: 0 10px;
    }
    a {
    color: #ffffff;
    text-decoration: none;
    }
    .top-level-item{
        font-family: "Futura", Sans-serif;
        font-size: 16px;
        text-transform: none;
        font-style: normal;
        text-decoration: none;
        line-height: 1.2em;
        padding: 30px;
        font-weight: 500;
    }
    .menu-subheading{
    font-family: "futura-pt-condensed", Sans-serif;
    text-transform: uppercase;
    line-height: 3.8em;
    }
    .menu-container{
        background: #091918 ;
        float: right;
        top:0;
        right:0;
        width:70vw;
        position:fixed;
        padding-top:100px;
        padding-left:100px;
        padding-bottom:100px;
        text-align: left;
        color: #ffffff;
        font-family: "futura-pt", Sans-serif;
        font-size: 24px;
        font-weight: 500;
        text-transform: none;
        font-style: normal;
        text-decoration: none;
        line-height: 1.2em;
    }
    .menu-container a{
        color: #ffffff;
    }
    .menu-container a:hover{

    color: #F5CC23;

    }
    .nav-bar{
        position:relative;
        z-index:99 !important;
        color:#ffffff;

    }
    .hovering{
        color:#fff;
    }

    .flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: center;
    }

    .flex-items {
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: top;
    order: 0;
    margin: 15px;
    min-width: 250px;
    }
    .menu-container ul li{
        display: block !important;
        clear:both;
        margin-bottom:.9em;
    }
    ul,
    li {
    margin-left: 0;
    padding-left: 0;
    }
    @media screen and (max-width: $small) {
        li.top-level-item {
        padding: 15px;
        }
  }
}

</style>
